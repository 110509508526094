import React from 'react'
import './container.scss'

const Container = ({ children, style, anim, delay, duration }) => {
    return(
        <div className={`containerOn ${style || ''}`} data-sal={anim} data-sal-delay={delay || '300'} data-sal-easing="ease" data-sal-duration={duration || "600"}>
            {children}
        </div>
    )
}

export default Container