import React from 'react'
import './flex.scss'

const Flex = ({children, style}) => {
    return(
        <div className={`flex ${style || ''}`}>
            {children}
        </div>
    )   
}

export default Flex