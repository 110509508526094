import React from "react"
import ReactHtmlParser from 'react-html-parser';
import "./paragraph.scss"

const Paragraph = ({text, style, anim, delay, duration}) => {
  return (
        <p className={`paragraph ${style || ''}`} data-sal={anim} data-sal-delay={delay || '300'} data-sal-easing="ease" data-sal-duration={duration || "600"}>{ReactHtmlParser(text)}</p>
    )
}

export default Paragraph
